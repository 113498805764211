<template>
  <div class="fr-document-manager">
    <v-row
      dense
      class="fr-document-action_bar_container px-2 ma-0 mb-2"
      justify="center"
    >
      <v-col>
        <ActionBarWrapper
          typology="action-bar"
          :model="actionBarModel"
          :mapping="dataObject.mapping"
          :documentName="file.file_name"
          :width="width"
          :actionStatus="actionStatus"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="dataObject.singleClickSignature !== '1'"
      dense
      justify="center"
      class="fr-document-signature-status-top-bar ma-0 pa-0"
    >
      <SignatureStatusBar
        :mapping="dataObject.mapping"
        :width="width+2"
        :actionStatus="actionStatus"
        @signField:prev="nextSignature"
        @signField:next="nextSignature"
        @signProcessCompleted="handleSignProcessCompleted"
        position="top"
      />
    </v-row>
    <v-row dense class="fr-document ma-0 py-0">
      <v-col cols="12">
        <v-fade-transition>
          <v-overlay v-if="loading"  z-index="999">
            <v-progress-circular
              indeterminate
              size="64"
              color="primary"
            ></v-progress-circular>
          </v-overlay>

        </v-fade-transition>
        <v-row
          dense
          justify="center"
          class="mb-4"
          style="max-width: 100vw !important"
        >
          <vue-pdf-embed
            v-if="dataObject.pdfRenderVersion === '1' && pdfFile"
            id="pdfcontainer"
            ref="pdfRef"
            class=""
            :textLayer="true"
            :source="pdfFile"
            @rendered="handleRendered"
            :width="width"
          />

            <div v-else-if="dataObject.pdfRenderVersion === '2' && pdfFile"  :style="`width: ${width}px`">
                <pdf class="d-none" :src="pdfFile" :page="page" @num-pages="changeTotalPages" />
                <div v-if="loadedReferenceComponent">
                    <div class="pdf-page mb-2" v-for="(page) in totalPages" :key="page">
                        <v-card
                          style="position:relative;"
                          :id="`pdfcontainer-${page}`">
                            <pdf class="textLayer" :src="pdfFile" :page="page"  @page-loaded="loaded" :height="height" />
                        </v-card>
                        <v-row justify="end" class="py-2"><span>{{dataObject.mapping.documentPanelPage}} {{page}}/{{totalPages}}</span></v-row>
                    </div>
                </div>
            </div>


          <div v-else-if="dataObject.pdfRenderVersion === 'paginationModel' && pdfFile"  :style="`width: ${width}px`">
            <MonoPagePDFRenderer
              :document="pdfFile"
              :document-actions="file.actions"
              :mapping="dataObject.mapping"
              :document-size="{ width: parseFloat(dataObject.document.width), height: parseFloat(dataObject.document.height) }"
              :pending-signatures="pendingSignatures"
              :signatures-applied="signaturesApplied"
              :single_click_signature="dataObject.singleClickSignature === '1'"
              :graphometric-signature-object="graphometricSignatureObject"
              @rendered="loading=false"
            />
          </div>

          <div v-if="file.actions && dataObject.singleClickSignature !== '1' && dataObject.pdfRenderVersion !== 'paginationModel'" class="d-none">
            <v-row
              dense
              v-for="(field, index) in file.actions"
              :key="field.id"
              :style="styleCalc(field, 'row')"
              :id="`${field.id}_${field.page}`"
              class="fr-sign-button fr-sign-button fr-signature-button-pending"
              :class="`sign-button-${index + 1}`"
            >
              <v-btn
                :style="styleCalc(field, 'button')"
                @click="handleAction(field)"
                :title="`${dataObject.mapping.documentPanelSignatureTag} ${index + 1} / ${file.actions.length}`"
              >
                <span class="fr-signature-button-pending__text">{{ textTruncate(dataObject.mapping.signatureActionButtonText, 8) }}</span>
                <span class="d-none fr-sign-button-text-completed">
                  <v-icon class="pb-1">done</v-icon>
                  {{ dataObject.mapping.documentPanelSignActive }}
                </span>
              </v-btn>

              <SignTag
                v-if="dataObject.pdfRenderVersion === '1' && pdfFile"
                :mapping="dataObject.mapping"
                :signButtonPosition="field"
                :documentSize="{width, height}"
                :signatureNumber="`${index + 1}/${file.actions.length}`"
                :style="styleCalc(field, 'tag')"
                :id="`signtag_${field.id}_${field.page}`"
              />
            </v-row>
          </div>
        </v-row>
      </v-col>

      <v-col cols="12" class="pa-0" >
        <SignatureRejectionManager
          v-if="rejectionDialog"
          :dataObject="dataObject"
          @click:outside="rejectionDialog = false"
          @rejectDocument="handleSignatureRejection"
        />
      </v-col>
    </v-row>
    <v-row
      dense
      justify="center"
      class="fr-signature-status-bar_container ma-0"
    >
      <SignatureStatusBar
        v-if="dataObject.singleClickSignature !== '1'"
        :mapping="dataObject.mapping"
        :width="width"
        :actionStatus="actionStatus"
        @signField:prev="nextSignature"
        @signField:next="nextSignature"
        @signProcessCompleted="handleSignProcessCompleted"
        position="bottom"
      />
    </v-row>

    <SingleClickSignatureBarWrapper
      v-if="dataObject.singleClickSignature === '1'"
      typology="single-click-signature-bar"
      :model="singleClickSignatureBarModel"
      :mapping="dataObject.mapping"
      :width="width"
      :activateOnScroll="dataObject.requireScrollToBottom === '1'"
      :allowRejection="dataObject.allowRejection === '1'"
      :rendererVersion="dataObject.pdfRenderVersion"
      @submitSignatures="fillAllSignatures"
      @showRejectDialog="handleShowRejectDialog"
    />

    <v-row
      v-if="dataObject.allowRejection === '1' && dataObject.singleClickSignature !== '1'"
      dense
      class="fr-document-reject-bar ma-0"
      justify="center"
    >
      <SignatureRejectionLink
        :mapping="dataObject.mapping"
        :width="width"
        @showRejectDialog="handleShowRejectDialog"
      />
    </v-row>
  </div>

</template>

<script>
  import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed"
  import SignatureStatusBar from "@/components/static/SignatureStatusBar"
  import SignTag from "@/components/SignTag"
  import { getFile, rejectDocument } from "@/services/generalService"
  import { generateFileDownload, hexToRgb, resetScroll, textTruncate, /*isMobileUserAgent,*/ pointsToPixels, pixelsToPoints } from "@/utils/utils"
  import { focusNextSignature } from "@/utils/modelUtils"
  import DOCUMENT_CONSTANTS from "@/constants/documentConstants"
  import SignatureRejectionLink from "@/components/static/SignatureRejectionLink.vue"
  import SignatureRejectionManager from "@/components/SignatureRejectionManager"
  import ActionBarWrapper from "@/components/wrappers/ActionBarWrapper"
  import SingleClickSignatureBarWrapper from "@/components/wrappers/SingleClickSignatureBarWrapper"
  import vModelMixin from '@/mixins/v-model-mixin'
  import Scaler from "@/utils/Scaler"
  import MonoPagePDFRenderer from "@/components/general/MonoPagePDFRenderer.vue"
  import pdf from "vue-pdf"

  export default {
    name: "SignPDFManager",
    mixins: [vModelMixin],
    components: {
      MonoPagePDFRenderer,
      SignatureRejectionLink,
      VuePdfEmbed,
      SignatureStatusBar,
      SignatureRejectionManager,
      SignTag,
      ActionBarWrapper,
      SingleClickSignatureBarWrapper,
      pdf
    },

    props: {
      file: Object,
      dataObject: Object,
      actionBarModel: String,
      singleClickSignatureBarModel: String,
      graphometricSignatureObject: Object
    },

    data() {
      return {
        textTruncate,
        focusNextSignature,
        loading: true,
        page: 1,

        width: !["xs", "sm"].includes(this.$vuetify.breakpoint.name) ? pointsToPixels(DOCUMENT_CONSTANTS.BASE_WIDTH) : (window.innerWidth - 50),
        height: !["xs", "sm"].includes(this.$vuetify.breakpoint.name) ? pointsToPixels(DOCUMENT_CONSTANTS.BASE_HEIGHT) : (window.innerWidth - 50) / DOCUMENT_CONSTANTS.PDF_EMBED_WIDTH_HEIGHT_FACTOR,

        focusedButton: 0,
        signaturesApplied: 0,

        loadedCompletely: false,

        otpSignatureDialog: false,
        otpSignatureDialogMode: null,

        rejectionDialog: false,
        pagesCanvas: [],

        totalPages: 0,
        loadedPages: 0,
        loadedReferenceComponent: false,
        pendingSignatures: []
      }
    },

    computed: {
        pdfRenderRef() {
            return this.$refs.pdfRef
        },
      actionStatus() {
        return {
          total: this.file.actions.length,
          completed: this.signaturesApplied
        }
      },

      pdfFile() {
        if (this.file) {
          return this._base64ToArrayBuffer(this.file.file_content)
        }

        return null
      },

      tooltipBackgroundColor() {
        const hexColor = getComputedStyle(document.body).getPropertyValue('--v-primary-base').slice(1)

        return `rgba(${hexToRgb(hexColor)}, 0.25)`
      },

      computedSizes() {
        return this.pagesCanvas
      }
    },

    mounted() {
      window.addEventListener("scroll", () => {
        this.handleTitleBarShadow()
      })

      this.$eventBus.on("zoomChange", (mode) => {
        this.handleZoom(mode)
      })

      this.$eventBus.on("fileDownload", () => {
        this.handleFileDownload()
      })

      this.$eventBus.on("increaseAppliedSignatures", (actionId) => {
        const pendingSignatureIndex = this.pendingSignatures.indexOf(actionId)
        this.pendingSignatures.splice(pendingSignatureIndex, 1)
        this.signaturesApplied++
      })

      this.$eventBus.on("signCompleted", () => {
        this.$eventBus.off("fileDownload")
        this.reset()
      })

      this.$eventBus.on("document-action:completed", (action) => {
        if (action.type.includes("signature_graphometric")) {
          this.$emit("showGraphometricSignatureDialog", action.id)
          // const pendingSignatureIndex = this.pendingSignatures.indexOf(action.id)
          // this.pendingSignatures.splice(pendingSignatureIndex, 1)

        } else if (action.type.includes("signature")/* && document.getElementById(action.id).classList.contains("fr-signature-completed")*/) {
          this.internalValue[action.type] = action.type in this.internalValue ?
            { ...this.internalValue[action.type], ...{ [action.id]: true } } :
            this.internalValue[action.type] = { [action.id]: true }

          this.setButtonAsSigned(action.id)

          const pendingSignatureIndex = this.pendingSignatures.indexOf(action.id)
          this.pendingSignatures.splice(pendingSignatureIndex, 1)

        }
      })

      this.pendingSignatures = this.file.actions.map(x => x.id)
    },

    methods: {
      nextSignature() {
        this.$eventBus.emit("nextSignature")

        if (this.dataObject.pdfRenderVersion !== "paginationModel") {
          focusNextSignature()
        }
      },

      _base64ToArrayBuffer(base64) {
          const binary_string = window.atob(base64);
          const len = binary_string.length;
          const bytes = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
          }
          return new Uint8Array(bytes.buffer)
        },

      loaded(page) {
        if (page){
          this.loadedPages++
          this.loading =  this.totalPages !== this.loadedPages

          if (this.totalPages === this.loadedPages) {
            this.newHandleRendered()
          }
        }
      },

      async changeTotalPages(value) {
          this.totalPages = value
          this.loadedReferenceComponent = true
      },

      async handleRendered() {
        const buttons = document.querySelectorAll(".fr-sign-button")

        for (let i = 1; i <= this.$refs.pdfRef.pageCount; i++) {
          const div = document.createElement('div')
            div.classList.add("pagenum-container")
          const html = `
            <span>${this.dataObject.mapping.documentPanelPage} ${i}/${this.$refs.pdfRef.pageCount}</span>
          `
          div.innerHTML = html.trim()
          document.querySelector(`#pdfcontainer-${i}`).appendChild(div)
          this.pagesCanvas[i-1] = {
            width: pixelsToPoints(document.querySelector(`#pdfcontainer-${i} div.textLayer`).clientWidth),
            height: pixelsToPoints(document.querySelector(`#pdfcontainer-${i} div.textLayer`).clientHeight),
          }

          new ResizeObserver(() => {
            this.loading = true
            if (document.querySelector(`#pdfcontainer-${i}`)) {
              this.pagesCanvas[i-1] = {
                width: pixelsToPoints(document.querySelector(`#pdfcontainer-${i}`).clientWidth),
                height: pixelsToPoints(document.querySelector(`#pdfcontainer-${i}`).clientHeight),
              }

            }
            this.loading = false
          }).observe(document.querySelector(`#pdfcontainer-${i}`))
        }

        buttons.forEach(btn => {
          const page = btn.getAttribute("id").split("_")[1]

          document.querySelector(`#pdfcontainer-${page}`).appendChild(btn)
        })


        this.loading = false
      },
      async newHandleRendered() {
        this.loading = true
        const buttons = document.querySelectorAll(".fr-sign-button")

        for (let i = 1; i <= this.totalPages; i++) {
          const div = document.createElement('div')

          document.querySelector(`#pdfcontainer-${i} .textLayer`).appendChild(div)
          this.pagesCanvas[i-1] = {
            width: pixelsToPoints(document.querySelector(`#pdfcontainer-${i} .textLayer`).clientWidth),
            height: pixelsToPoints(document.querySelector(`#pdfcontainer-${i} .textLayer`).clientHeight),
          }

          new ResizeObserver(() => {
            this.loading = true
            if (document.querySelector(`#pdfcontainer-${i}`)) {
              this.pagesCanvas[i-1] = {
                width: pixelsToPoints(document.querySelector(`#pdfcontainer-${i}`).clientWidth),
                height: pixelsToPoints(document.querySelector(`#pdfcontainer-${i}`).clientHeight),
              }

            }
            this.loading = false
          }).observe(document.querySelector(`#pdfcontainer-${i}`))
        }

        buttons.forEach(btn => {
          const page = btn.getAttribute("id").split("_")[1]

          document.querySelector(`#pdfcontainer-${page}`).appendChild(btn)
        })


        this.loading = false
      },

      handleZoom(value) {
        this.loading = true
        switch (this.$vuetify.breakpoint.name) {
          case "xl":
          case "lg":
          case "md":
            if (value === "+" && DOCUMENT_CONSTANTS.MAX_WIDTH > (this.width - DOCUMENT_CONSTANTS.WIDTH_ZOOM_STEP)) {
              this.width += DOCUMENT_CONSTANTS.WIDTH_ZOOM_STEP
              this.height += DOCUMENT_CONSTANTS.HEIGHT_ZOOM_STEP

            } else if(value === "-" && DOCUMENT_CONSTANTS.DESKTOP_WIDTH_ZOOM_LIMIT < (this.width - DOCUMENT_CONSTANTS.WIDTH_ZOOM_STEP)) {
              this.width -= DOCUMENT_CONSTANTS.WIDTH_ZOOM_STEP
              this.height -= DOCUMENT_CONSTANTS.HEIGHT_ZOOM_STEP

            } else if (value !== "+" && value !== "-") {
              const size = DOCUMENT_CONSTANTS.ZOOM_PERCENTAGE_TO_SIZE[value]

              this.width = size.width
              this.height = size.height
            }

            this.$emit('changeSize', { width: this.width, height: this.height })
            break

        }

        this.loading = false
      },

      styleCalc(field, type) {
        const docSize = { width: parseFloat(this.dataObject.document.width), height: parseFloat(this.dataObject.document.height) }

        if (!this.loading && document.querySelector(`#pdfcontainer-${field.page} .textLayer`)) {
          const position = Scaler.getPositionBasedOnDocumentSize(field, this.computedSizes[field.page-1])
          const size = Scaler.getSizeBasedOnDocumentSize(docSize, field, this.computedSizes[field.page-1])
          if (type === "row") {
            return `bottom: ${position.y > this.height ? this.height : position.y}pt;`
          }

          if (type === "tag") {
            return `
              left: ${-300 * 0.75}px;
              height: 100px;
            `
          }

          if (type === "button") {
            let completedSignature = null
            if (document.querySelector(`#${field.id}_${field.page}`)) {
              completedSignature = document.querySelector(`#${field.id}_${field.page}`).classList.contains("fr-signature-completed")
            }
            let opacity = 1
            const completedStyle = `
              border-top: 2px solid var(--v-primary-base);
              border-bottom: 2px solid var(--v-primary-base);
              border-radius: 0;
            `

            if (document.querySelector(`#${field.id}_${field.page}`) && completedSignature) {
              opacity = 0
            }

            let style = `
              width: ${pointsToPixels(size.width)}px;
              max-width: ${pointsToPixels(size.width)}px;
              left: ${position.x > this.width ? this.width : position.x}pt;
              height: ${pointsToPixels(size.height)}px;
              background-color: rgba(${hexToRgb(this.$vuetify.theme.themes.light.primary)}, ${opacity});
            `


            if (completedSignature) {
              style += completedStyle
            }

            if (field.style) {
              Object.keys(field.style).forEach(x => {
                style += `${x}: ${field.style[x]} !important;`
              })
            }

          return style
        }

          return ""
        }

      },

      handleAction(data) {
        if (data.type.includes("signature") && !document.querySelector(`#${data.id}_${data.page}`).classList.contains("fr-signature-completed")) {
          if (data.type.includes("signature_graphometric")) {
            this.$emit("showGraphometricSignatureDialog", data.id)

          } else if (data.type in this.internalValue) {
            this.internalValue[data.type] = { ...this.internalValue[data.type], ...{ [data.id]: true } }
            this.setButtonAsSigned(`${data.id}_${data.page}`)

          } else {
            this.internalValue[data.type] = { [data.id]: true }
            this.setButtonAsSigned(`${data.id}_${data.page}`)
          }


        }
      },

      setButtonAsSigned(id){
        document.getElementById(id).classList.remove("fr-signature-button-pending")
        document.getElementById(id).classList.add("fr-signature-completed")
        document.getElementById(id).querySelector(".fr-sign-button-text-completed").classList.remove("d-none")

        document.getElementById(id).querySelector(".fr-signature-button-pending__text").classList.add("d-none")
        document.getElementById(id).querySelector(".fr-sign-button-text-completed").classList.remove("d-none")

        if (this.dataObject.pdfRenderVersion === 'paginationModel') {
          document.getElementById(`signaturetag_${id}`).classList.add("d-none")
        } else if(this.dataObject.pdfRenderVersion === "2") {
          // document.getElementById(id).classList.add("d-none")
        } else {
          document.getElementById(`signtag_${id}`).classList.add("d-none")
        }

        this.signaturesApplied++
      },

      fillAllSignatures() {
        this.signaturesApplied = 0

        const signatureIds = this.file.actions.map(x => x.id)
        this.internalValue = signatureIds.reduce((acc, item) => {
          acc.signature[item] = true;
          return acc;
        }, { signature: {} })

        if (this.dataObject.signaturePopup === "") {
          this.handleSignProcessCompleted()
        } else {
          this.$eventBus.emit("showSignatureDialog")
        }

      },

      handleSignProcessCompleted() {
        this.$eventBus.off("fileDownload")
        this.$emit("submitSignatures", this.internalValue)
      },

      handleFileDownload() {
        getFile(this.dataObject.currentHash).then(response => {
          if (response.data.error === 0) {
            generateFileDownload(response.data.extra.fileContent, response.data.extra.fileName, response.data.extra.fileType)
          }
        })
      },

      handleTitleBarShadow() {
        const element = document.querySelector("#fr-document_title-bar")

        if (element) {
          const pos = window.scrollY

          if (pos === 0) {
            element.classList.remove("document-title-bar-shadow__active")
          } else {
            element.classList.add("document-title-bar-shadow__active")
          }
        }
      },

      reset() {
        resetScroll()
        document.querySelectorAll(".fr-sign-button").forEach(x => {
          x.classList.remove("fr-signature-completed")
          x.classList.remove("v-btn--disabled")
        })

        // this.userActions = {}
        this.internalValue = {}
        this.focusedButton = 0
        this.signaturesApplied = 0
      },

      handleShowRejectDialog(){
        this.rejectionDialog = true
      },

      handleSignatureRejection(reason) {
        rejectDocument(this.dataObject.currentHash, { reason: reason }).then(response => {
          if (response.data.error === 0) {
            this.rejectionDialog = false
            this.$eventBus.emit("signRejected")
          }
        })
      }
    },

    watch: {
      // loading(value){
      //   if( this.dataObject.pdfRenderVersion === '2' && !value ){
      //     this.newHandleRendered()
      //   }
      // },
      signaturesApplied(newVal) {
        if (newVal === this.file.actions.length) {
          this.$eventBus.emit("showSignatureDialog")
        }
      }
    }
  }
</script>

<style lang="scss">
  .vue-pdf-embed>div {
    border:solid 1px var(--v-background-color-darken1) !important;
  }

  .vue-pdf-embed>div:not(:first-child) {
    margin-top: 50px !important;
  }

  .fr-document-manager {
    margin-top: 20px;
    max-width: 100vw !important;
  }

  .fr-document, .fr-document-action_bar_container, .fr-signature-status-bar_container, .fr-document-reject-bar {
    max-width: 100vw !important;
  }

  .fr-signature-status-bar_container {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .fr-document-signature-status-top-bar {
    position: sticky !important;
    top: 0;
    z-index: 100;
  }

  .fr-document-reject-bar {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  .textLayer {
      opacity: 1 !important;
  }
  @media only screen and (max-width: 915px ) {
    .fr-document-manager {
      margin-top: 20px;
    }
    .fr-document-action_bar_container {
      z-index: 100;
    }

    .fr-document {
      padding: 40px 0;
    }

  }

</style>
