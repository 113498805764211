<template>
  <v-dialog
    v-model="dialogOpen"
    :max-width="error ? 500 : 700"
    persistent
  >
    <v-form id="ama-signature-form">
      <v-card class="fr-ama-signature-model-1 pt-5 px-2">
        <v-card-subtitle>
          <v-row dense justify="center" class="pb-0">
            <v-col cols="12" class="text-center">
              <v-icon id="ama-signature-icon" size="65" color="primary">lock_clock</v-icon>
            </v-col>
            <v-col class="text-center">
              <h1
                id="ama-signature-title"
                class="fr-identification_model-1__title font-style-title"
                v-html="error ? dataObject.mapping.amaOtpPanelErrorTitle : dataObject.mapping.amaPanelTitle">
              </h1>

              <v-divider />

              <p
                id="ama-signature-text"
                class="fr-identification_model-1__introduction-text font-style-introduction mt-1"
                v-html="error ? dataObject.mapping.amaOtpPanelErrorDescription : dataObject.mapping.amaPanelIntroductionText"
              >
              </p>
            </v-col>
          </v-row>
        </v-card-subtitle>

        <v-card-text v-if="!error" class="pb-2">
          <v-row dense class="ma-0 py-3" justify="center">
            <v-col cols="10">
              <v-text-field
                id="ama-signature-phone"
                name="phoneNumber"
                type="tel"
                outlined
                dense
                hide-details
                class="centered-input px-1"
                v-model="credentials.user"
                :placeholder="dataObject.mapping.amaPanelPhonePlaceholder"
                autocomplete="new-phoneNumber"
              />
            </v-col>
          </v-row>
          <v-row dense class="ma-0 py-3" justify="center">
            <v-col cols="10">
              <v-text-field
                id="ama-signature-pin"
                name="pin"
                type="password"
                outlined
                dense
                hide-details
                class="centered-input px-1"
                v-model="credentials.pin"
                :placeholder="dataObject.mapping.amaPanelPinPlaceholder"
                autocomplete="new-password"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-row justify="space-around" dense class="ma-0 pb-4">
            <v-btn v-if="error"
              id="ama-signature-retry"
              class="fr-ama-signature-model-1_accept_button"
              @click="handleRetry"
            >
              {{ dataObject.mapping.retryText }}
            </v-btn>
            <v-btn v-else
              id="ama-signature-submit"
              :loading="loading"
              class="fr-ama-signature-model-1_accept_button"
              :disabled="!submitDisabled"
              @click="handleSubmit"
            >
              {{ dataObject.mapping.amaPanelOtpSubmit }}
            </v-btn>
          </v-row>
        </v-card-actions>

      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { sendAMACredentials } from "@/services/generalService"
import ama_cert from "@/constants/ama_cert"
import { encryptData, loadPublicKey } from "@/utils/cryptoUtils";

export default {
  name: "AmaSignatureManager",
  props: {
    dataObject: Object,
    userActions: Object,
  },

  data() {
    return {
      dialogOpen: true,
      credentials: {
        user: null,
        pin: null,
      },
      dialCode: null,
      loading: false,
      input: false,
      error: false

    }
  },

  computed: {
    submitDisabled() {
      if (!this.input) return false

      const validCredentials = Object.values(this.credentials).some(x => x === null || x === "")
      const validDialCode = !this.dialCode || this.dialCode === ""

      return !(validCredentials || validDialCode)
    }
  },

  async mounted() {
    if (!this.error) {
      this.setupIntl()
    }


  },

  methods: {
    setupIntl() {
      this.input = document.getElementById("ama-signature-phone")
      window.intlTelInput(this.input, {
        showSelectedDialCode: true,
        utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@21.1.4/build/js/utils.js",
        preferredCountries: ["es", "pt"],
        countrySearch: false,
      })


      for (const listEl of document.querySelectorAll(".iti__country-list>li")) {
        listEl.addEventListener("click", this.handleDialCodeClick)
      }
    },

    handleDialCodeClick(event) {
      this.dialCode = event.target.parentElement.getAttribute("data-dial-code")
    },

    async handleSubmit() {
      const key = loadPublicKey(ama_cert)

      this.loading = true
      const iti = window.intlTelInputGlobals.getInstance(document.getElementById("ama-signature-phone"))
      const { dialCode } = iti.getSelectedCountryData()

      const phoneAndDialCode = `+${dialCode}${this.credentials.user}`

      const response = await sendAMACredentials(
        this.dataObject.currentHash,
        {
          user: await encryptData(key, phoneAndDialCode),
          pin: await encryptData(key, this.credentials.pin)
        }
      )

      if (response.data.message === "ama_otp_sent") {
        this.$emit("otpSent")
      } else {
        this.error = true
      }

      this.loading = false
    },

    resetFields() {
      this.credentials = {
        user: null,
        pin: null
      }

      this.dialCode = null
    },


    handleRetry() {
      this.error = false
      this.resetFields()

      setTimeout(() => {
        this.setupIntl()
      }, 200)
    }
  },

  beforeUnmount() {
    for (const listEl of document.querySelectorAll(".iti__country-list>li")) {
      listEl.removeEventListener("click", this.handleDialCodeClick)
    }
  }

}
</script>

<style lang="scss">
.v-text-field__slot > .iti {
  width: 100% !important;

  input {
    padding: 0 !important;
  }
}


div.v-dialog:has(#ama-signature-form) {
  overflow: visible !important;
}

.fr-ama-signature-model-1_accept_button {
  background-color: var(--v-primary-base) !important;
  color: var(--v-primary-text-base) !important;
}

.fr-ama-signature-model-1 .v-alert__content{
  width: 100%;
  padding-right: 32px !important;
}

@media only screen and (max-width: 915px) {
  .fr-ama-signature-model-1__text {
    font-weight: 400 !important;
    font-size: 0.88rem !important;
  }

  .fr-ama-signature-model-1 > .v-otp-input .v-input__control .v-input__slot {
    width: 40px !important;
    height: 40px !important;
    padding: 0 !important;
  }

  .fr-ama-signature-model-1 >.v-otp-input .v-input {
    padding: 4px !important;
  }

  .fr-ama-signature-model-1 > .v-text-field--filled > .v-input__control > .v-input__slot,
  .v-text-field--full-width > .v-input__control > .v-input__slot,
  .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 40px !important;
  }

  .fr-ama-signature-model-1_accept_button {
    width: 200px !important;
    background-color: var(--v-primary-base) !important;
    color: var(--v-primary-text-base) !important;

    font-size: 1.25rem;
    font-weight: 400;
  }

}

</style>