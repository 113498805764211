export default `-----BEGIN CERTIFICATE-----
MIIDQjCCAioCCQCzT7WtwlLzRDANBgkqhkiG9w0BAQsFADBjMQswCQYDVQQLEwJJ
VDEMMAoGA1UEChMDQU1BMQ8wDQYDVQQHEwZMaXNib24xDzANBgNVBAgTBkxpc2Jv
bjELMAkGA1UEBhMCUFQxFzAVBgNVBAMTDkNNRC5hc3NpbmF0dXJhMB4XDTIzMDQw
NTE0NDkwOVoXDTI2MDQwNDE0NDkwOVowYzELMAkGA1UECxMCSVQxDDAKBgNVBAoT
A0FNQTEPMA0GA1UEBxMGTGlzYm9uMQ8wDQYDVQQIEwZMaXNib24xCzAJBgNVBAYT
AlBUMRcwFQYDVQQDEw5DTUQuYXNzaW5hdHVyYTCCASIwDQYJKoZIhvcNAQEBBQAD
ggEPADCCAQoCggEBAMytTBfxV2ezp2lAsAJvv8lH3SdEuNJ57bCUbGF6OcGRUoIj
5vVpaKFBZgTOgudfGopXp2lTyaO/EksHqQYhrTcH/eYwlNvtjwEdrn+WR+X0m5Cm
UvODuSnpbJH/gEKHk+eeYkmUxVqLgvrZjEXK7SRwYUYeJydIj5zUC7/at7NDXTCq
jnKQjG2g0CMX12SPZHMeJPE70/+DcQTq0UwxbPWqIyWWlTG+Ikmzdhh2NYt6z2KN
SV9K7VEvnh/zkntfhyUOsm0ffK6lnSESDBoKLGD2Ir23bPBZBetL8lr6RLNr9Pqq
oBfrLf4BpO/nodDswLChX2+pC+6wBeQLQ77F+jkCAwEAATANBgkqhkiG9w0BAQsF
AAOCAQEAr+tShuBiayXB143d9oom09g69Pf9X2K3AXQCDwVOIHCYOjST5pdWfoAx
ZGB13kuVU0w2kq5MRwtFGCWMXq4jZfeMF7CpyJoxzEtpDgP/SMQUT2mCFqZuRp33
bbggws6Hw5UlhXvkCCltmM5+q0tvefQNHLCQCxdp5LASZ/kNJs7ypQEI6V6ZjIw0
GBCn5kKClDrJICwAaUuEDsD5z7IwRiyAuoKsTtcHconwEZCkZLl5uBgXGXqeiKM4
fJHf4tKrvHmOcPlk1bp1GbXUaO/GiimjYHSvZMV860lKXOGCxgYYZMs0d2/aoQ6h
DnNq6v8qtoPArn7Fb8ZOfvMq/Om3fA==
-----END CERTIFICATE-----`
