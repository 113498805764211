import {
  VMain,
  VBtn,
  VCard,
  VCardTitle,
  VCardSubtitle,
  VCardText,
  VTextField,
  VAutocomplete,
  VCardActions,
  VRow,
  VCol,
  VDivider,
  VIcon,
  VImg,
  VSelect,
  VToolbar,
  VContainer,
  VFooter,
  VAlert,
  VExpandTransition,
  VBtnToggle,
  VMenu,
  VList,
  VListItem,
  VListItemTitle,
  VOtpInput,
  VAppBar,
  VAppBarNavIcon,
  VSpacer,
  VToolbarTitle,
  VProgressLinear,
  VDialog,
  VSlideXTransition
} from 'vuetify/lib'

export default {
  VAlert,
  VAutocomplete,
  VBtn,
  VCard,
  VCardTitle,
  VCardSubtitle,
  VCardText,
  VCardActions,
  VCol,
  VContainer,
  VDivider,
  VExpandTransition,
  VFooter,
  VIcon,
  VImg,
  VMain,
  VRow,
  VSelect,
  VTextField,
  VToolbar,
  VBtnToggle,
  VMenu,
  VList,
  VListItem,
  VListItemTitle,
  VOtpInput,
  VAppBar,
  VAppBarNavIcon,
  VSpacer,
  VToolbarTitle,
  VProgressLinear,
  VDialog,
  VSlideXTransition
}
